<template>
  <div
    v-if="item && Object.keys(item).length && item.active"
    @click="toDisease(item.link)"
    class="area-item"
  >
    <picture v-if="formattedImageList && formattedImageList.length">
      <source
        v-for="(image, ind) in formattedImageList"
        :key="ind"
        :srcset="image.src"
        :media="`(max-width: ${image.media}px)`"
      />
      <img
        class="area-item__image"
        :src="formattedImageList[formattedImageList.length - 1].src"
      />
    </picture>
    <div v-else class="area-item__noimage"></div>
    <div class="area-item__title" v-html="item.title"></div>
    <div class="area-item__button">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.75 4.5L20.25 12L12.75 19.5"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="square"
        />
        <path
          d="M18.75 12L3.75 12"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="square"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DiseaseItem",
  props: {
    item: Object,
  },
  data: () => ({
    media: {
      mobile: 767,
      tablet: 1220,
      desktop: 1919,
      desktop_l: 2559,
      desktop_xl: 3839,
      desktop_xxl: 9999999,
    },
  }),
  computed: {
    ...mapGetters(["NosologyDetail"]),
    formattedImageList() {
      let imageList = [];
      let usedImages = Object.keys(this.item)
        .filter((el) => el.includes("card_banner_"))
        .map((el) => el.replace("card_banner_", ""));
      for (let imgFormat of usedImages) {
        if (
          this.item["card_banner_" + imgFormat] &&
          Object.keys(this.media).includes(imgFormat)
        ) {
          imageList.push({
            src:
              this.$root.host +
              this.item["card_banner_" + imgFormat].replace("http:", "https:"),
            media: this.media[imgFormat],
          });
        }
      }
      imageList.sort((a, b) => (a.media > b.media ? 1 : -1));
      return imageList;
    },
  },
  methods: {
    toDisease(to) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "disease page click", {
          "disease page click": {
            "disease page name": this.item.title,
            "therapeutic area": this.NosologyDetail.title,
             ...this.$root.ymFields
          },
        });
      }
      window.open(to, "_self")
    },
  },
};
</script>

<style lang="scss" scoped>
.area-item {
  padding: 32px;
  position: relative;
  height: 440px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 56, 101, 0.6),
      rgba(0, 56, 101, 0.6)
    );
    content: "";
    display: block;
    z-index: 3;
    opacity: 0;
    transition: 0.3s;
  }

  &:hover::before {
    opacity: 1;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 32px;
    background: rgba(235, 239, 238, 0.95);
    z-index: -10;
    opacity: 0;
    transition: 0.2s linear;
    @media screen and (max-width: 1220px) {
      padding: 24px;
    }

    &-title {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #830051;
    }

    &-item {
      margin-bottom: 12px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
      transition: 0.3s linear;
      @media screen and (max-width: 1220px) {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 22px;
      }
      &:hover {
        color: #d0006f;
      }
    }

    &-button {
      margin-top: auto;
      margin-left: auto;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: transparent;
      color: #830051;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1220px) {
    height: 357px;
    padding: 24px;
  }

  @media screen and (max-width: 767px) {
    height: auto;
    padding: 0;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: 767px) {
      height: 164px;
      position: static;
    }
  }

  &__noimage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #830051;

    @media screen and (max-width: 767px) {
      height: 164px;
      position: static;
    }
  }

  &__title {
    position: relative;
    z-index: 4;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      margin-top: 8px;
      font-size: 14px;
      line-height: 21px;
    }
  }

  &__button {
    margin-top: auto;
    margin-left: auto;
    position: relative;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #830051;
    border-radius: 999px;
    color: #e6ccdc;
    cursor: pointer;
    transition: 0.2s linear;
    &:hover {
      background-color: #9a3374;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &:hover &__nav {
    @media screen and (min-width: 768px) {
      z-index: 10;
      opacity: 1;
    }
  }

  &:hover &__button {
    background-color: transparent;
  }
}
</style>